
  .hover-container {
    position: relative;
  }
  
  .hover-target {
    position: relative;
    cursor: pointer;
  }
  
  .hover-popup {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: -45px; /* Align the top of the popup with the top of the trigger */
    left: 40%; /* Position the popup to the right of the trigger */
    width: 300px; /* Adjust the width as needed */
    font-size: .8rem;
    background-color: #fff;
    border-radius: 8px;
    padding: 1.5em;
    z-index: 9999999999;
    transform: scale(0);
    transition: transform 200ms ease;
    transform-origin: top left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .hover-popup-1 {
    top: -15px !important;
    left: 100% !important;
  }
  .hover-popup-2{
    top: -13px !important;
    left: 80% !important;
  }
  .hover-target:hover + .hover-popup,
  .hover-target:focus + .hover-popup,
  .hover-popup:hover {
    transform: scale(1);
  }
  
  .hover-popup :not(:first-child) {
    margin-top: 1rem;
  }
  
  .hover-popup span {
    color: rgb(200, 20, 0);
    font-weight: 700;
  }
  
  .hover-popup::before {
    content: '';
    position: absolute;
    border-left: 10px solid #fff; /* Change this to match the popup background color */
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    top: 20px; /* Adjust to position the arrow vertically */
    left: -10px; /* Position the arrow to the left of the popup */
  }
  
  .hover-popup::after {
    content: '';
    position: absolute;
    top: -1rem;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  .widtth-50-pxx{
    width: 50px !important;
  }
  .line-height-and-zoom{
    line-height: 26px;
    text-align: center;
    font-size: 19px !important;
    color: unset;
  }
  .line-height-and-zom-steps{
    padding-inline-start: 0px;
    line-height: 26px;
    font-size: 19px;
  }
  .width-75-pxx {
    width:80px;
    /* width: 80px; */
    /* text-decoration: underline; */
  }
  .width-105-px {
    width:80px;
    /* width: 130px !important; */
    /* text-decoration: underline; */
}

.width-115-px {
  /* width: 120px !important; */
  width:80px;
  /* text-decoration: underline; */
}
.list-style-type-disc{
  list-style-type: disc;
}
.list-style-type-discli::marker {
  content: unset;
}


@media screen and (max-width: 550px) {
  .hover-popup {
    left: 20%;
}
.hover-popup::before{
  border-left: unset;
}
}