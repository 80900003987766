@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@100&family=Libre+Baskerville:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* General Page Styling */
.offerings-container {
  background-color: #fdfcfaff;
  padding: 50px 20px;
}
@media (max-width: 700px) {
  .row {
    display: block !important; /* Ensures each item is stacked */
  }
}

/* Section Title */
.section-title {
  font-size: 26px;
  font-weight: 700;
  color: #000;
}

/* Card Styling */

/* Card Title */
.offerings-title {
  font-size: 18px;
  font-weight: 700;
  color: #2c2c2c;
  position: relative;
}

/* Arrow Icon */
.arrow-icon {
  float: right;
  font-size: 16px;
  color: #c29f68;
}

/* List Styling */
.offerings-container ul {
  padding-left: 15px;
}

.offerings-container ul li {
  font-size: 16px;
  color: black;
  margin-bottom: 8px;
}

.offerings-container ul li .anchore-tags {
  color: #a56a3a;
}

.offerings-container ul li .anchore-tags:hover{
  color: #a56a3a;
  text-decoration: underline !important;
}
/* Highlighted Text */
strong {
  color: #c29f68;
  font-weight: 600;
}

/* Border Bottom for Sections */
.offerings-title::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #c29f68;
  margin-top: 5px;
}
.mainCard {
  height: fit-content;
}
.offerings-card {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.offerings-card {
  width: 100%;
  /* text-align: left; */
  background-color: #fff !important;
  box-shadow: 0 8px 22px -6px rgba(73, 73, 73, 0.12),
    0 14px 64px -4px rgba(71, 71, 71, 0.12) !important;
  border-radius: 11px !important;
  margin: 0 30px 0 0;
  border: 1px solid #fff !important;
  /* padding: 54.5px 40.48px; */

  /* background: #ffffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
  position: relative;
  padding: 20px;
}
.offerings-card {
  margin-top: 27px;
  background-color: #fdfcfa;
  border: 2px solid #f1e6dc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.offerings-header {
  background-color: #f3eae0; /* Light beige background */
  font-weight: bold;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  border-left: 5px solid #a56a3a; /* Brown vertical left border */
  font-size: 20px;
}

.offerings-subtitle {
  font-weight: bold;
  color: #a56a3a;
  display: block;
  padding-bottom: 5px;
  margin-top: 20px; /* Increased top margin */
  margin-bottom: 10px;
  font-size: 16px;
}

.offerings-list li {
  margin-top: 12px; /* Space above list items */
  margin-bottom: 5px;
  font-size: 16px;
}
.card-body {
  padding-top: 15px;
}

/* Space between sections */
.list-unstyled {
  margin-top: 15px; /* Increase spacing above first item */
}

.sub-list {
  padding-left: 20px;
  list-style: none;
}

.sub-list-item {
  font-size: 14px;
  color: #666;
  /* Underline */
}
.sub-list-item-last {
  border-bottom: 1px solid #eadacb;
}

.corner-fold {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 15px;
  height: 15px;
  background-color: #bf8958;
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
}

.height-80-px {
  height: 80px !important;
  transition: height;
  transition-timing-function: ease-in-out;
}
.height-0-px {
  height:0px !important;
  transition: height;
  transition-timing-function: ease-in-out;
}
.offerings-header::after {
  content: "";
  background-image: url("./up.png");
  width: 30px;
  height: 30px;
  background-size: contain;
  cursor: pointer;
}
.offerings-header-up::after {
  content: "";
  background-image: url("./down.png");
  width: 30px;
  height: 30px;
  background-size: contain;
  cursor: pointer;
}
.offerings-header-inner::after {
  content: "";
  background-image: url("./up.png");
  width: 12px;
  height: 12px;
  background-size: contain;
  cursor: pointer;
}
.offerings-header-up-inner::after{
  content: "";
  background-image: url("./down.png");
  width: 12px;
  height: 12px;
  background-size: contain;
  cursor: pointer;
}
/* @media (max-width: 600px) { */
  .offerings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
/* } */
.offerings-list li a{
  color: black;
  cursor: pointer;
}
.offerings-list li a:hover{
text-decoration: underline !important;
}
.gray{
  color: gray !important;
}
.brown-overview ,.brown-overview:active{
  color: #a56a3a !important;
}
.offerings-container li::marker {
  content: unset;
}
.offerings-list {
  list-style-type: disc;
}
.sub-list{
  list-style-type: circle;
}