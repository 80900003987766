@font-face {
  font-family: "Oakes-Grotesk-Light";
  src: url("../fonts/Oakes-Grotesk-Light.woff") format("woff"),
    url("../fonts/Oakes-Grotesk-Light.woff2") format("woff2");
  /* url('Oakes-Grotesk-Light.svg#Oakes-Grotesk-Light') format('svg'),
url('Oakes-Grotesk-Light.eot?#iefix') format('embedded-opentype'),
url('Oakes-Grotesk-Light.ttf') format('truetype'); */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Oakes-Grotesk-Medium";
  src: url("../fonts/Oakes-Grotesk-Medium.woff") format("woff"),
    url("../fonts/Oakes-Grotesk-Medium.woff2") format("woff2");
  /* url('Oakes-Grotesk-Medium.svg#Oakes-Grotesk-Medium') format('svg'),
url('Oakes-Grotesk-Medium.eot'),
url('Oakes-Grotesk-Medium.eot?#iefix') format('embedded-opentype'),
url('Oakes-Grotesk-Medium.ttf') format('truetype'); */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Oakes-Grotesk-Regular";
  src: url("../fonts/Oakes-Grotesk-Regular.woff") format("woff"),
    url("../fonts/Oakes-Grotesk-Regular.woff2") format("woff2");
  /* url('Oakes-Grotesk-Regular.svg#Oakes-Grotesk-Regular') format('svg'),
url('Oakes-Grotesk-Regular.eot'),
url('Oakes-Grotesk-Regular.eot?#iefix') format('embedded-opentype'),
url('Oakes-Grotesk-Regular.ttf') format('truetype'); */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Oakes-Grotesk-SemiBold";
  src: url("../fonts/Oakes-Grotesk-SemiBold.woff") format("woff"),
    url("../fonts/Oakes-Grotesk-SemiBold.woff2") format("woff2");
  /* url('Oakes-Grotesk-SemiBold.svg#Oakes-Grotesk-SemiBold') format('svg'),
url('Oakes-Grotesk-SemiBold.eot'),
url('Oakes-Grotesk-SemiBold.eot?#iefix') format('embedded-opentype'),
url('Oakes-Grotesk-SemiBold.ttf') format('truetype'); */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Oakes-Grotesk-Bold";
  src: url("../fonts/Oakes-Grotesk-Bold.woff") format("woff"),
    url("../fonts/Oakes-Grotesk-Bold.woff2") format("woff2");
  /* url('Oakes-Grotesk-Bold.svg#Oakes-Grotesk-Bold') format('svg'),
url('Oakes-Grotesk-Bold.eot'),
url('Oakes-Grotesk-Bold.eot?#iefix') format('embedded-opentype'),
url('Oakes-Grotesk-Bold.ttf') format('truetype'); */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
body {
  margin: 0 !important;
  padding: 0;
  font-family: inherit !important;
}

.login-register-saction {
  width: 100vw;
  background: url(/localImages/Bg1.jpg);
  height: 100vh;
  vertical-align: middle;
  display: table-cell;
  background-size: cover;
  background-position: center;
}
.login-register-saction .container {
  width: 100% !important;
  margin: 0;
  max-width: inherit;
}
.testim {
  width: 100%;
}
.testim .wrap {
  position: relative;
  width: 100%;
  margin: auto;
}

.testim .arrow {
  display: block;
  position: absolute;
  color: #eee;
  cursor: pointer;
  font-size: 2em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 5px;
  z-index: 2222;
}

.testim .arrow:before {
  cursor: pointer;
}

.testim .arrow:hover {
  color: #ea830e;
}

.testim .arrow.left {
  left: 10px;
}

.testim .arrow.right {
  right: 10px;
}

.testim .dots {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 20px 0 0 0;
}

.testim .dots .dot {
  list-style-type: none;
  display: inline-block;
  width: 26.3px;
  height: 8.15px;
  border-radius: 200px;
  border: 1px solid #666;
  margin: 0 10px;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  background-color: #fff;
}

.testim .dots .dot.active,
.testim .dots .dot:hover {
  background: #d5a67b;
  border-color: #d5a67b;
}

.testim .dots .dot.active {
  -webkit-animation: testim-scale 0.5s ease-in-out forwards;
  -moz-animation: testim-scale 0.5s ease-in-out forwards;
  -ms-animation: testim-scale 0.5s ease-in-out forwards;
  -o-animation: testim-scale 0.5s ease-in-out forwards;
  animation: testim-scale 0.5s ease-in-out forwards;
}

.testim .cont {
  position: relative;
  overflow: hidden;
  margin: 5rem 0 0;
}

.testim .cont > div {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  opacity: 0;
  margin: 0 auto;
  width: 100%;
}

.testim .cont > div.inactive {
  opacity: 1;
}

.testim .cont > div.active {
  position: relative;
  opacity: 1;
}

.testim .cont div .img img {
  display: inline;
  width: 533px;
  height: auto;
  margin: auto;
}

.testim .cont div h2 {
  color: #485257;
  font-size: 32px;
  margin: 50px 0 0 0;
  font-family: "Oakes-Grotesk-Bold";
  font-weight: 700;
}

.testim .cont div p {
  font-size: 18px;
  color: #485257;
  width: 100%;
  margin: 15px 0 0;
  font-weight: 400;
  font-family: "Oakes-Grotesk-Regular";
  line-height: 29px;
  display: inline-block;
}

.testim .cont div.active .img img {
  -webkit-animation: testim-show 0.5s ease-in-out forwards;
  -moz-animation: testim-show 0.5s ease-in-out forwards;
  -ms-animation: testim-show 0.5s ease-in-out forwards;
  -o-animation: testim-show 0.5s ease-in-out forwards;
  animation: testim-show 0.5s ease-in-out forwards;
}

.testim .cont div.active h2 {
  -webkit-animation: testim-content-in 0.4s ease-in-out forwards;
  -moz-animation: testim-content-in 0.4s ease-in-out forwards;
  -ms-animation: testim-content-in 0.4s ease-in-out forwards;
  -o-animation: testim-content-in 0.4s ease-in-out forwards;
  animation: testim-content-in 0.4s ease-in-out forwards;
}

.testim .cont div.active p {
  -webkit-animation: testim-content-in 0.5s ease-in-out forwards;
  -moz-animation: testim-content-in 0.5s ease-in-out forwards;
  -ms-animation: testim-content-in 0.5s ease-in-out forwards;
  -o-animation: testim-content-in 0.5s ease-in-out forwards;
  animation: testim-content-in 0.5s ease-in-out forwards;
}

.testim .cont div.inactive .img img {
  -webkit-animation: testim-hide 0.5s ease-in-out forwards;
  -moz-animation: testim-hide 0.5s ease-in-out forwards;
  -ms-animation: testim-hide 0.5s ease-in-out forwards;
  -o-animation: testim-hide 0.5s ease-in-out forwards;
  animation: testim-hide 0.5s ease-in-out forwards;
}

.testim .cont div.inactive h2 {
  -webkit-animation: testim-content-out 0.4s ease-in-out forwards;
  -moz-animation: testim-content-out 0.4s ease-in-out forwards;
  -ms-animation: testim-content-out 0.4s ease-in-out forwards;
  -o-animation: testim-content-out 0.4s ease-in-out forwards;
  animation: testim-content-out 0.4s ease-in-out forwards;
}

.testim .cont div.inactive p {
  -webkit-animation: testim-content-out 0.5s ease-in-out forwards;
  -moz-animation: testim-content-out 0.5s ease-in-out forwards;
  -ms-animation: testim-content-out 0.5s ease-in-out forwards;
  -o-animation: testim-content-out 0.5s ease-in-out forwards;
  animation: testim-content-out 0.5s ease-in-out forwards;
}

@-webkit-keyframes testim-scale {
  0% {
    -webkit-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -webkit-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -webkit-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -webkit-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-moz-keyframes testim-scale {
  0% {
    -moz-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -moz-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -moz-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -moz-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-ms-keyframes testim-scale {
  0% {
    -ms-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -ms-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -ms-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -ms-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-o-keyframes testim-scale {
  0% {
    -o-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -o-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -o-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -o-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@keyframes testim-scale {
  0% {
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-webkit-keyframes testim-content-in {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-moz-keyframes testim-content-in {
  from {
    opacity: 0;
    -moz-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
}

@-ms-keyframes testim-content-in {
  from {
    opacity: 0;
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@-o-keyframes testim-content-in {
  from {
    opacity: 0;
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes testim-content-in {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes testim-content-out {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-moz-keyframes testim-content-out {
  from {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -moz-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-ms-keyframes testim-content-out {
  from {
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-o-keyframes testim-content-out {
  from {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@keyframes testim-content-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@-webkit-keyframes testim-show {
  from {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes testim-show {
  from {
    opacity: 0;
    -moz-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
}

@-ms-keyframes testim-show {
  from {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@-o-keyframes testim-show {
  from {
    opacity: 0;
    -o-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes testim-show {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes testim-hide {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@-moz-keyframes testim-hide {
  from {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -moz-transform: scale(0);
    transform: scale(0);
  }
}

@-ms-keyframes testim-hide {
  from {
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
  }
}

@-o-keyframes testim-hide {
  from {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -o-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes testim-hide {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}

@media all and (max-width: 300px) {
  body {
    font-size: 14px;
  }
}

@media all and (max-width: 500px) {
  .testim .arrow {
    font-size: 1.5em;
  }

  .testim .cont div p {
    line-height: 25px;
  }
}
.login-saction-right {
  display: inline-block;
  width: 100%;
  padding: 0 0 0 129.59px;
}
.login-saction-right h2 {
  margin: 59.41px 0 0 0;
  color: #485257;
  font-size: 40px;
  font-weight: 300;
  font-family: "Oakes-Grotesk-Light" !important;
  line-height: 52px;
  display: inline-block;
  /* width: 31%; */
}
.form-l---r {
  margin: 43.5px 0 0 0;
  display: inline-block;
  width: 100%;
}
.form-l---r .from-fild {
  display: inline-block;
  width: 100%;
  margin: 0 0 33px 0;
  position: relative;
}
.form-l---r label {
  display: inline-block;
  width: 100%;
  color: #a4a4a4;
  font-size: 14px;
  margin: 0 0 9.5px 0;
  font-family: "Oakes-Grotesk-Light";
}
.form-l---r input {
  width: 100%;
  border: 1px solid #cacaca;
  border-radius: 5px;
  height: 60px;
  padding: 0 52px;
  color: #233b58;
  font-size: 16px;
  font-family: "Oakes-Grotesk-Regular";
  font-weight: 400;
}
.form-l---r .text-are-client {
  width: 100%;
  border: 1px solid #cacaca;
  border-radius: 5px;
  /* height: 60px; */
  /* padding: 0 52px; */
  color: #233b58;
  font-size: 16px;
  font-family: "Oakes-Grotesk-Regular";
  font-weight: 400;
}
.form-l---r select {
  width: 100%;
  height: 60px;
  border: 1px solid #cacaca;
  border-radius: 5px;
  /* background-color: transparent; */
  padding: 0 52px;
  color: #233b58;
  font-family: "Oakes-Grotesk-Regular";
  cursor: pointer;
  ont-size: 16px;
}
.form-item__element--select.has-placeholder {
  color: #757575;
}
input:focus-visible {
  outline: inherit;
  border-color: #d5a67b;
}
.form-l---r form {
  width: 350px;
}
.icon {
  position: absolute !important;
  left: 19px;
  top: 50px;
}

.copy-icon {
  position: absolute; /* Position the icon absolutely */
  right: 10px; /* Position the icon 10px from the right */
  top: 50%; /* Center the icon vertically */
  transform: translateY(-50%); /* Adjust for vertical alignment */
  cursor: pointer; /* Change cursor to pointer on hover */
}
.left-side {
  position: relative; /* Make it the reference point for absolute positioning */
}
.right-side{
  position: relative;
}
.phone-input {
  padding-left: 30px; /* Add padding to the left to avoid overlap with the icon */
}
.from-fild .icon {
  margin-left: unset !important;
  margin-top: unset !important;
  color: unset !important;
}
.icon-right {
  position: absolute;
  right: 19px;
  top: 51px;
}
.forget-fild {
  display: inline-block;
  width: 100%;
  text-align: right;
}
.forget-fild a {
  color: #d5a67b !important;
  font-size: 14px;
  font-weight: 400;
  font-family: "Oakes-Grotesk-Regular";
  display: inline-block;
  width: 100%;
}
.dont-have-acc {
  display: inline-block;
  width: 100%;
  text-align: left;
  margin: 100.46px 0 0 0;
}
.dont-have-acc .modal {
  z-index: 9999;
}
.dont-have-acc p {
  margin: 0;
  color: #233b58;
  font-size: 16px;
  font-weight: 400;
  font-family: "Oakes-Grotesk-Regular";
  display: inline-block;
  width: 100%;
}
.dont-have-acc .btn.btn-info.btn-lg {
  width: auto;
  padding: 0;
  background-color: transparent;
  border: none;
  color: #d5a67b;
  font-size: 16px;
  font-family: "Oakes-Grotesk-Regular";
  margin: -1px 0 0 0;
}
.bution {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 43.05px 0 0 0;
}
.bution .button-bx {
  background-color: #d5a67b;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-family: "Oakes-Grotesk-Medium";
}
.bution .disabledBtn {
  background-color: #808080 !important;
  color: #fff !important;
}
.dont-have-acc .modal-dialog {
  max-width: 605.5px !important;
  margin: 12rem auto !important;
  height: 530.74px;
  border-radius: 21px !important;
  border: 2px solid #d5a67b !important;
  background-color: #fff !important;
  box-shadow: 0px 48px 90px rgba(0, 0, 0, 0.4) !important;
  overflow: hidden;
  vertical-align: middle;
}
.dont-have-acc .modal-body {
  padding: 0;
  border: none;
}
.dont-have-acc .modal-content {
  border: none;
  width: 100% !important;
  height: 100%;
}
.dont-have-acc .modal-dialog::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: url("/images/Union.png");
  width: 100%;
  height: 100%;
  top: 0;
  background-size: cover;
  z-index: 1;
}
.dont-have-acc .modal-body {
  text-align: center;
  padding: 54.09px 10px;
}
.dont-have-acc .modal-body ul {
  display: inline-block;
  width: 100%;
  margin: 79.28px 0 0;
}
.dont-have-acc .modal-body ul li {
  display: inline-block;
  width: auto;
  margin: 0 16px;
  cursor: pointer;
}
.dont-have-acc .modal-body ul li div {
  display: inline-block;
  text-align: center;
  width: 220px;
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 11px;
  box-shadow: 0px 18px 27px rgba(213, 166, 123, 0.46);
  height: 220px;
  padding: 50px 5px;
}
.dont-have-acc .modal-body ul li div:hover {
  border-color: #d5a67b;
  transition-duration: 0.8s;
}
.dont-have-acc .modal-body ul li div img {
  display: inline;
  width: 25%;
}
.dont-have-acc .modal-body ul li div span {
  display: inline-block;
  width: 100%;
  margin: 19.08px 0 0 0;
  color: #485257;
  font-size: 16px;
  letter-spacing: 0.01em;
  font-weight: 500;
  line-height: 20px;
  font-family: "Oakes-Grotesk-Medium";
}
.dont-have-acc .modal-body ul li div img {
  display: inline;
  width: 68px;
  height: 53.83px;
  object-fit: revert-layer;
}

.dont-have-acc .modal {
  text-align: center;
  padding: 0 !important;
}

.dont-have-acc .modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.dont-have-acc .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  width: 100%;
}
.register-right h2 {
  margin: 36.93px 0 0 0;
}
.register-right .form-l---r {
  margin: 27.31px 0 0 0;
}
.register-right .from-fild {
  margin-bottom: 25.09px;
}
.register-right .dont-have-acc {
  margin: 54.71px 0 0 0;
}

.check-box input[type="checkbox"] + label {
  display: block;
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
}

.check-box input[type="checkbox"] {
  display: none;
}
.check-box input[type="checkbox"] + label::before {
  content: "\2714";
  border: 1px solid #d5a67b;
  border-radius: 0.2em;
  display: inline-block;
  width: 22.52px;
  height: 22.52px;
  padding-left: 4px;
  padding-bottom: 4px;
  margin-right: 10px;
  vertical-align: bottom;
  color: transparent;
  /* transition: .2s; */
}
.check-box label {
  color: #485257 !important;
  font-weight: 500 !important;
  font-family: "Oakes-Grotesk-Medium";
}
.check-box input[type="checkbox"] + label:active:before {
  transform: scale(0);
}
.check-box input[type="checkbox"]:checked + label::before {
  background-color: #d5a67b;
  border-color: #d5a67b;
  color: #fff;
}
.check-box input[type="checkbox"]:disabled + label:before {
  transform: scale(1);
  border-color: #aaa;
}
.check-box input[type="checkbox"]:checked:disabled + label:before {
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
}
.register-right .bution {
  margin: 36.6px 0 0;
}
.dont-have-acc p a {
  color: #d5a67b !important;
}
.dont-have-acc p .confirmation-register {
  color: #d5a67b !important;
  display: inline-block;
  cursor: pointer;
}
.dont-have-acc p .confirmation-register:hover {
  text-decoration: underline;
}
.register-medium #testim-content {
  margin: 9rem 0 0;
}
.bution .btn.btn-info.btn-lg {
  width: 100%;
  background-color: #d5a67b;
  border: none;
  height: 60px;
  font-size: 16px;
  font-family: "Oakes-Grotesk-Regular";
}

.bution .modal {
  text-align: center;
  padding: 0 !important;
}

.bution .modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.bution .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  width: 100%;
}
.register-medium .modal-dialog {
  width: 605.5px !important;
  height: 503.74px;
  background-color: #fff;
  border: 2px solid #d5a67b;
  border-radius: 21px;
  box-shadow: 0px 48px 90px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  text-align: center;
}
.register-medium .modal-dialog .modal-content {
  background-color: transparent;
  border: none;
  height: 100%;
  border-radius: 0;
}
.register-medium .modal-dialog::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 90%;
  top: 7%;
  bottom: 0;
  height: 90%;
  background: url(/images/Union.png);
  background-size: cover;
  background-position: 100%;
  margin: 0 auto;
}
.register-medium .modal-dialog .modal-body {
  padding: 0 !important;
}
.register-medium .modal-dialog .icon-pop {
  margin: 54.95px 0 0 0;
}
.register-medium .modal-dialog h3 {
  color: #485257;
  font-size: 24px;
  font-weight: 500;
  font-family: "Oakes-Grotesk-Medium";
  letter-spacing: 1px;
  display: inline-block;
  margin: 27.09px 0 0 0;
  width: 100%;
  line-height: 35px;
}
.register-medium .modal-dialog p {
  margin: 10px auto 0;
  letter-spacing: 1px;
  color: #000;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  font-family: "Oakes-Grotesk-Light";
  padding: 0 1rem;
}
.register-medium .modal-dialog a,
.register-medium .modal-dialog .register-medium-confirnation ,.client-reschedule-btns {
  display: inline-block;
  background-color: #d5a67b;
  margin: 26.1px 0 0 0;
  box-shadow: 0px 5px 10px #e0e4ee;
  width: 184px;
  height: 61px;
  line-height: 61px;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Oakes-Grotesk-Medium";
  cursor: pointer;
}
.btn.btn-info.btn-lg:focus {
  box-shadow: none !important;
}
.btn.btn-info.btn-lg:active {
  background-color: #d5a67b !important;
}

#heading {
  text-transform: uppercase;
  color: #673ab7;
  font-weight: normal;
}

#msform {
  position: relative;
  margin-top: 0;
  width: 100%;
  display: inline-block;
}
#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative;
}
.form-card {
  text-align: left;
}
#msform fieldset:not(:first-of-type) {
  display: none;
}
#msform .action-button {
  width: 184px;
  background: #d5a67b;
  color: white;
  border: 0 none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 5px 10px #e0e4ee;
  height: 61px;
  font-family: "Oakes-Grotesk-Medium";
  font-weight: 500;
  font-size: 16px;
  margin: 58.9px 50.5px 58.9px;
}

#msform .action-button:hover,
#msform .action-button:focus {
  background-color: #fff;
  color: #d5a67b;
}

#msform .action-button-previous {
  width: 184px;
  background: #fff;
  color: #2f2f2f;
  border: 0 none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 5px 10px #e0e4ee;
  height: 61px;
  font-family: "Oakes-Grotesk-Medium";
  font-weight: 500;
  font-size: 16px;
}
.from-inner-box input {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #cacaca;
  height: 61.37px;
  padding: 0 16px;
  font-family: "Oakes-Grotesk-Medium";
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 26.62px 0;
  box-sizing: border-box !important;
}
.from-inner-box input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bcbfc0;
}

.from-inner-box input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bcbfc0;
}

.from-inner-box input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bcbfc0;
}
#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  background-color: #d5a67b;
  color: #fff;
}

.card {
  z-index: 0;
  border: none;
  position: relative;
}

.fs-title {
  font-size: 25px;
  color: #673ab7;
  margin-bottom: 15px;
  font-weight: normal;
  text-align: left;
}

.purple-text {
  color: #673ab7;
  font-weight: normal;
}

.steps {
  font-size: 25px;
  color: gray;
  margin-bottom: 10px;
  font-weight: normal;
  text-align: right;
}

.fieldlabels {
  color: gray;
  text-align: left;
}

.header-from {
  display: inline-block;
  width: 100%;
  text-align: center;
  background: url(/images/top-header-from.png);
  padding: 36.83px 0;
  background-size: cover;
  background-position: 100% 100%;
}
.header-from #progressbar {
  margin: 0 auto;
  width: 75%;
  display: inline-block;
  position: relative;
  z-index: 999;
}
#progressbar .active {
  color: #673ab7;
}

#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
}
.user-menu-wrap {
  position: relative;
  width: 100%;
  text-align: right;
}

.menu-container {
  /* visibility: hidden;
    opacity: 0; */
}
.menu-container.active {
  visibility: visible;
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
.user-menu {
  position: absolute;
  right: -22px;
  background-color: #f8f8f8;
  width: 256px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 13px;
  z-index: 1001;
  border-radius: 6px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2) !important;
}
.user-menu .profile-highlight {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 16px;
  margin-bottom: 6px;
}
.user-menu li {
  display: inline-block;
  width: 100%;
  text-align: left;
}
.user-menu .profile-highlight img {
  width: 48px;
  height: 48px;
  border-radius: 25px;
  -o-object-fit: cover;
  object-fit: cover;
}
.user-menu .profile-highlight .details {
  display: flex;
  flex-direction: column;
  margin: auto 12px;
}
.user-menu .profile-highlight .details #profile-name {
  font-weight: 600;
  font-size: 16px;
}
.user-menu .profile-highlight .details #profile-footer {
  font-weight: 300;
  font-size: 14px;
  margin-top: 4px;
}
.user-menu .footer {
  border-top: 1px solid #e0e0e0;
  padding-top: 6px;
  margin-top: 6px;
}
.user-menu .footer .user-menu-link {
  font-size: 13px;
}
.user-menu .user-menu-link {
  display: flex;
  text-decoration: none;
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  padding: 10px 20px;
  border-bottom: 1px solid #dcdcdc;
  font-family: "Oakes-Grotesk-Regular";
}
.user-menu li:last-child a {
  border-bottom: none;
}
.user-menu .user-menu-link div {
  margin: auto 10px;
}
.user-menu .user-menu-link:hover {
  background-color: #f5f5f5;
  color: #333333;
}
.user-menu:before {
  position: absolute;
  top: -16px;
  left: 120px;
  display: inline-block;
  content: "";
  border: 8px solid transparent;
  border-bottom-color: #e0e0e0;
}
.user-menu:after {
  position: absolute;
  top: -14px;
  left: 121px;
  display: inline-block;
  content: "";
  border: 7px solid transparent;
  border-bottom-color: #ffffff;
}
.headar-saction {
  display: inline-block;
  width: 100%;
  padding: 10px 0;
  background-color: #fff;
  box-shadow: 0 0 2px 0 #dcdcdc;
}
.mini-photo-wrapper {
  color: #485257 !important;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  font-family: "Oakes-Grotesk-Medium";
  margin: 6px 0 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.dropdowan {
  margin: 0 0 0 5px;
}
.mini-photo {
  margin: 0 10px 0 0;
  float: left;
}
.mini-photo-wrapper span {
  margin: 5px 0 0 0;
  display: inline-block;
}
.from-saction {
  display: table-cell;
  width: 100vw;
  padding: 0;
  margin: 0;
  background: url(/images/from-bg.png);
  background-size: cover;
  background-position: center center;
  height: 100vh;
  text-align: center;
  padding-bottom: 121.73px;
  vertical-align: middle;
  position: relative;
}
.from-saction .row.justify-content-center {
  margin: 38.33px 38.33px 0 38.33px !important;
  display: inline-block;
  width: 983.9px;
  text-align: left;
  border-radius: 10px;
  overflow: hidden;
}
#progressbar li span {
  display: inline-block;
  width: 67px;
  margin: 0;
  background-color: #fff;
  height: 67px;
  border-radius: 50%;
  border: 1px solid #f4e8dd;
  box-shadow: 0px 8px 22px rgba(0, 0, 0, 0.05);
  line-height: 90px;
}
#progressbar li .wight {
  display: none;
}
#progressbar li strong {
  display: inline-block;
  width: 100%;
  margin: 18px 0 0 0;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-family: "Oakes-Grotesk-Regular";
}
#progressbar .active span {
  background-color: #d5a67b;
  border-color: #d5a67b;
}
#progressbar .active .black {
  display: none;
}
#progressbar .active .wight {
  display: inline;
}
#progressbar .active strong {
  color: #d5a67b;
}
.header-from #progressbar::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 74%;
  margin: 0 auto;
  background-color: rgba(213, 166, 123, 0.26);
  height: 1px;
  top: 30%;
  z-index: -1;
}
.body-from {
  background-color: rgba(255, 255, 255, 0.94);
  display: inline-block;
  width: 100%;
  text-align: center;
}
#msform fieldset {
  background: transparent !important;
  border: 0 none;
  border-radius: 0 !important;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 0;
  position: relative;
}
.next-previos-box {
  display: inline-block;
  width: 100%;
  text-align: center !important;
  margin: 58.1px 0;
}

.left-side {
  float: left;
  width: 48%;
  text-align: left;
}
.right-side {
  float: right;
  width: 48%;
}
.from-inner-box label {
  margin: 0 0 10px 0;
  width: 100%;
  display: inline-block;
  color: #2f2f2f;
  font-size: 16px;
  font-weight: 500;
  font-family: "Oakes-Grotesk-Medium";
  text-align: left;
}
.iti {
  position: relative;
  display: inline-block;
  width: 100%;
}
.from-inner-box i {
  font-style: normal;
  color: #d5a67b;
}

.chosen-select {
  width: 100%;
}
.chosen-select-deselect {
  width: 100%;
}
.chosen-container {
  display: inline-block;
  font-size: 14px;
  position: relative;
  vertical-align: middle;
  width: 100% !important;
}
.chosen-container .chosen-drop {
  background: #ffffff;
  border: 1px solid #cccccc;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  -webkit-box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
  margin-top: -1px;
  position: absolute;
  top: 100%;
  left: -9000px;
  z-index: 1060;
}
.chosen-container.chosen-with-drop .chosen-drop {
  left: 0;
  right: 0;
}
.chosen-container .chosen-results {
  color: #555555;
  margin: 0 4px 4px 0;
  max-height: 240px;
  padding: 0 0 0 4px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.chosen-container .chosen-results li {
  display: none;
  line-height: 1.42857143;
  list-style: none;
  margin: 0;
  padding: 5px 6px;
}
.chosen-container .chosen-results li em {
  background: #feffde;
  font-style: normal;
}
.chosen-container .chosen-results li.group-result {
  display: list-item;
  cursor: default;
  color: #999;
  font-weight: bold;
}
.chosen-container .chosen-results li.group-option {
  padding-left: 15px;
}
.chosen-container .chosen-results li.active-result {
  cursor: pointer;
  display: list-item;
}
.chosen-container .chosen-results li.highlighted {
  background-color: #428bca;
  background-image: none;
  color: white;
}
.chosen-container .chosen-results li.highlighted em {
  background: transparent;
}
.chosen-container .chosen-results li.disabled-result {
  display: list-item;
  color: #777777;
}
.chosen-container .chosen-results .no-results {
  background: #eeeeee;
  display: list-item;
}
.chosen-container .chosen-results-scroll {
  background: white;
  margin: 0 4px;
  position: absolute;
  text-align: center;
  width: 321px;
  z-index: 1;
}
.chosen-container .chosen-results-scroll span {
  display: inline-block;
  height: 1.42857143;
  text-indent: -5000px;
  width: 9px;
}
.chosen-container .chosen-results-scroll-down {
  bottom: 0;
}
.chosen-container .chosen-results-scroll-down span {
  background: url("/images/chosen-sprite.png") no-repeat -4px -3px;
}
.chosen-container .chosen-results-scroll-up span {
  background: url("/images/chosen-sprite.png") no-repeat -22px -3px;
}
.chosen-container-single .chosen-single {
  background-color: #ffffff;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border: 1px solid #cccccc;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #555555;
  display: block;
  height: 34px;
  overflow: hidden;
  line-height: 34px;
  padding: 0 0 0 8px;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
}
.chosen-container-single .chosen-single span {
  display: block;
  margin-right: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chosen-container-single .chosen-single abbr {
  background: url("/images/chosen-sprite.png") right top no-repeat;
  display: block;
  font-size: 1px;
  height: 10px;
  position: absolute;
  right: 26px;
  top: 12px;
  width: 12px;
}
.chosen-container-single .chosen-single abbr:hover {
  background-position: right -11px;
}
.chosen-container-single
  .chosen-single.chosen-disabled
  .chosen-single
  abbr:hover {
  background-position: right 2px;
}
.chosen-container-single .chosen-single div {
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
}
.chosen-container-single .chosen-single div b {
  background: url("/images/chosen-sprite.png") no-repeat 0 7px;
  display: block;
  height: 100%;
  width: 100%;
}
.chosen-container-single .chosen-default {
  color: #777777;
}
.chosen-container-single .chosen-search {
  margin: 0;
  padding: 3px 4px;
  position: relative;
  white-space: nowrap;
  z-index: 1000;
}
.chosen-container-single .chosen-search input[type="text"] {
  background: url("/images/chosen-sprite.png") no-repeat 100% -20px, #ffffff;
  border: 1px solid #cccccc;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  margin: 1px 0;
  padding: 4px 20px 4px 4px;
  width: 100%;
}
.chosen-container-single .chosen-drop {
  margin-top: -1px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.chosen-container-single-nosearch .chosen-search input {
  position: absolute;
  left: -9000px;
}
.chosen-container-multi .chosen-choices {
  background-color: #ffffff;
  border: 1px solid #cacaca;
  cursor: text;
  margin: 0;
  overflow: hidden;
  position: relative;
  padding: 0 16px;
  height: 61.37px !important;
  line-height: 61.37px;
  border-radius: 5px;
}
.chosen-container-multi .chosen-choices li {
  float: left;
  list-style: none;
}
.chosen-container-multi .chosen-choices .search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.chosen-container-multi .chosen-choices .search-field input[type="text"] {
  background: transparent !important;
  border: 0 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #555555;
  height: 32px;
  margin: 0;
  padding: 4px;
  outline: 0;
}
.chosen-container-multi .chosen-choices .search-field .default {
  color: #999;
}
.chosen-container-multi .chosen-choices .search-choice {
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  background-color: #d5a67b !important;
  border: 1px solid #d5a67b;
  color: #fff;
  cursor: default;
  margin: 10px 11px 0 0;
  padding: 0 20px 0 0;
  position: relative;
  text-align: center;
  display: inline-block !important;
  width: 121px;
  border-radius: 5px;
  height: 39px;
  line-height: 38px;
}
.chosen-container-multi .chosen-choices .search-choice .search-choice-close {
  background: url(/images/crose.png);
  display: block;
  font-size: 1px;
  height: 12px;
  position: absolute;
  right: 8px;
  top: 13px;
  width: 12px;
  cursor: pointer;
  background-size: cover;
}
.chosen-container-multi .chosen-choices .search-choice-focus {
  background: #d4d4d4;
}
.chosen-container-multi .chosen-results {
  margin: 0 0 0 0;
  padding: 0;
  text-align: left;
}
.chosen-container-multi .chosen-drop .result-selected {
  display: none;
}
.chosen-container-active .chosen-single {
  border: 1px solid #66afe9;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(82, 168, 236, 0.6);
  -webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
  -o-transition: border linear 0.2s, box-shadow linear 0.2s;
  transition: border linear 0.2s, box-shadow linear 0.2s;
}
.chosen-container-active.chosen-with-drop .chosen-single {
  background-color: #ffffff;
  border: 1px solid #66afe9;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(82, 168, 236, 0.6);
  -webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
  -o-transition: border linear 0.2s, box-shadow linear 0.2s;
  transition: border linear 0.2s, box-shadow linear 0.2s;
}
.chosen-container-active.chosen-with-drop .chosen-single div {
  background: transparent;
  border-left: none;
}
.chosen-container-active.chosen-with-drop .chosen-single div b {
  background-position: -18px 7px;
}
.chosen-container-active .chosen-choices .search-field input[type="text"] {
  color: #111 !important;
}
.chosen-container-active.chosen-with-drop .chosen-choices {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.chosen-disabled {
  cursor: default;
  opacity: 0.5 !important;
}
.chosen-disabled .chosen-single {
  cursor: default;
}
.chosen-disabled .chosen-choices .search-choice .search-choice-close {
  cursor: default;
}
.chosen-rtl {
  text-align: right;
}
.chosen-rtl .chosen-single {
  padding: 0 8px 0 0;
  overflow: visible;
}
.chosen-rtl .chosen-single span {
  margin-left: 26px;
  margin-right: 0;
  direction: rtl;
}
.chosen-rtl .chosen-single div {
  left: 7px;
  right: auto;
}
.chosen-rtl .chosen-single abbr {
  left: 26px;
  right: auto;
}
.chosen-rtl .chosen-choices .search-field input[type="text"] {
  direction: rtl;
}
.chosen-rtl .chosen-choices li {
  float: right;
}
.chosen-rtl .chosen-choices .search-choice {
  margin: 6px 5px 3px 0;
  padding: 3px 5px 3px 19px;
}
.chosen-rtl .chosen-choices .search-choice .search-choice-close {
  background-position: right top;
  left: 4px;
  right: auto;
}
.chosen-rtl.chosen-container-single .chosen-results {
  margin: 0 0 4px 4px;
  padding: 0 4px 0 0;
}
.chosen-rtl .chosen-results .group-option {
  padding-left: 0;
  padding-right: 15px;
}
.chosen-rtl.chosen-container-active.chosen-with-drop .chosen-single div {
  border-right: none;
}
.chosen-rtl .chosen-search input[type="text"] {
  background: url("/images/chosen-sprite.png") no-repeat -28px -20px, #ffffff;
  direction: rtl;
  padding: 4px 5px 4px 20px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-resolution: 144dpi) {
  .chosen-rtl .chosen-search input[type="text"],
  .chosen-container-single .chosen-single abbr,
  .chosen-container-single .chosen-single div b,
  .chosen-container-single .chosen-search input[type="text"],
  .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
  .chosen-container .chosen-results-scroll-down span,
  .chosen-container .chosen-results-scroll-up span {
    background-image: url("/images/chosen-sprite@2x.png") !important;
    background-size: 52px 37px !important;
    background-repeat: no-repeat !important;
  }
}
.chosen-container-multi .chosen-results li {
  padding: 8px 20px;
  color: #000;
  font-weight: 500;
  font-family: "Oakes-Grotesk-Medium";
  font-size: 14px;
}
.chosen-container-multi .chosen-results li:hover {
  background-color: #d5a67b;
  color: #fff;
}
.other-lang {
  width: 100%;
  text-align: left;
  display: inline-block;
  color: #d5a67b;
  margin: 11.3px 0 11.3px 0;
  font-family: "Oakes-Grotesk-Medium";
}
.InputGroup {
  display: inline-block;
  width: 100%;
  text-align: left;
}
.InputGroup input[type="radio"] {
  visibility: hidden;
  height: 0;
  width: 0;
  display: none;
}
.InputGroup label {
  display: inline-block;
  cursor: pointer;
  background-color: #fff;
  color: #2f2f2f;
  border-radius: 5px;
  border: 1px solid #efefef;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  font-family: "Oakes-Grotesk-Regular";
  margin: 0;
  padding: 0 40px;
}
.InputGroup label:last-of-type {
  margin-right: 0;
}
.InputGroup input[type="radio"]:checked + label {
  background-color: #d5a67b;
  color: #fff;
}
.InputGroup input[type="radio"]:hover:not(:checked) + label {
  background-color: var(--color-pink);
  color: var(--color-black);
}
.radio-box {
  margin: 0 12px 0 0;
  float: left;
  width: auto;
  position: relative;
  background-color: #fff;
  border-radius: 5px;
}
.InputGroup label::before {
  content: "";
  position: absolute;
  left: 15px;
  width: 16.89px;
  height: 16.89px;
  border: 1px solid #d5a67b;
  border-radius: 50%;
  top: 15px;
}
.InputGroup input[type="radio"]:checked + label::before {
  background-color: #fff;
  border-color: #fff;
}
.from-inner-box textarea {
  width: 100%;
  border: 1px solid #cacaca;
  border-radius: 5px;
  height: 163.66px;
  padding: 10px;
}
.footer-saction {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 15px 0;
}
.footer-box.footer-one p {
  margin: 0;
  color: #d5a67b;
  font-size: 18px;
  font-weight: 400;
  font-family: "Oakes-Grotesk-Regular";
  width: 100%;
  display: inline-block;
  line-height: 44px;
}
.footer-box.footer-one p img {
  width: 40.71px;
  margin: 0 13.29px 0 0;
  float: left;
}
.footer-box-centar {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.footer-box-centar ul {
  display: inline-block;
  width: 100%;
  margin: 0;
}
.footer-box-centar ul li {
  display: inline-block;
  border-right: 1px solid #2f2f2f;
  width: auto;
  margin: 14px 10px 0 0;
  padding: 0 10px 0 0;
  line-height: 14px;
}
.footer-box-centar ul li a {
  color: #2f2f2f;
  font-weight: 500;
  font-size: 16px;
  font-family: "Oakes-Grotesk-Medium";
}
.footer-box-right {
  display: inline-block;
  width: 100%;
  text-align: right;
}
.footer-box-right p {
  margin: 8px 0 0 0;
  color: #485257;
  font-size: 14px;
  font-weight: 400;
  font-family: "Oakes-Grotesk-Regular";
}
.footer-box-centar ul li:last-child {
  border-right: none;
}
.moretext {
  display: none;
}
.moreless-button {
  text-align: left;
  width: 100%;
  float: left;
  margin: 11.3px 0 0 0;
  color: #d5a67b !important;
  font-size: 16px;
  font-weight: 500;
  font-family: "Oakes-Grotesk-Medium";
}
.search-field .default {
  color: #bcbfc0 !important;
  font-weight: 500;
  font-family: "Oakes-Grotesk-Medium";
}
.check-field input[type="radio"] {
  width: 100%;
  margin: 0;
  padding: 0;
  opacity: 1;
  appearance: none;
  height: 100%;
  position: absolute;
  background-color: transparent;
  cursor: pointer;
}

.check-field input[type="radio"]:checked {
  border: 1px solid #d5a67b;
}

.check-field input[type="radio"]:checked::before {
  border-color: #d5a67b;
  background-color: #d5a67b;
}
.check-field input[type="radio"]:checked::after {
  content: "";
  background: url(/images/check.png);
  width: 9.93px;
  height: 8.83px;
  position: absolute;
  left: 20px;
  background-size: cover;
  top: 19px;
}
.check-field input[type="radio"]:focus {
  outline: none;
}
.check-field {
  float: left;
  width: auto;
  margin: 0 22px 0 0;
  position: relative;
}
.check-field label {
  margin: 0;
  border: 1px solid #efefef;
  border-radius: 5px;
  background-color: #fff;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  padding: 0 20px 0 45px;
  box-sizing: border-box;
}
.check-field input[type="radio"]::before {
  content: "";
  width: 21px;
  height: 21px;
  position: absolute;
  border: 1px solid #cacaca;
  border-radius: 5px;
  left: 15px;
  top: 13px;
}
.search-name input {
  margin-bottom: 0;
}
.search-name h6 {
  text-align: left;
  margin: 15.94px 0 0 0;
  color: #485257;
  font-size: 13px;
  font-weight: 400;
  font-family: "Oakes-Grotesk-Regular";
  line-height: 18px;
}
.strclint {
  padding: 0 27px 0 45px !important;
}
.strclint::after {
  content: "";
  background: url(/images/qu.png);
  position: absolute;
  width: 17px;
  height: 17px;
  background-size: cover;
  right: 5px;
  top: 16px;
}
.as-medium .check-field {
  margin: 0 0 12.39px 0;
}
.full {
  float: left;
  width: 100%;
}
.payment-saction .check-field {
  margin-bottom: 12.88px;
}
.signup {
  padding: 0;
  margin: 0;
  text-align: left;
  width: 100%;
  color: #858585;
  font-size: 14px;
  font-family: "Oakes-Grotesk-Regular";
  font-weight: 400;
}
.signup a {
  color: #4e64dd;
  text-decoration: underline;
  margin: 0 13px 23.86px 0;
  display: inline-block;
}
.ul-li-list {
  display: inline-block;
  width: 100%;
  text-align: left;
  margin: 13.68px 0 0 0;
}
.ul-li-list li {
  float: left;
  width: 184.43px;
  margin: 0 9.9px 13.92px 0;
  text-align: center;
  list-style: none;
  height: 150px;
}
.ul-li-list li a {
  display: inline-block;
  width: 100%;
  padding: 22.65px 14.4px;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 5px;
  height: 145.65px;
}
.ul-li-list li a span {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.ul-li-list li a p {
  margin: 18.41px 0 0;
  font-size: 12px;
  line-height: 17px;
  font-family: "Oakes-Grotesk-Regular";
  font-weight: 300;
}
.ul-li-list li a:hover {
  border-color: #d5a67b;
}
.all-devices {
  padding-bottom: 20px;
}
.browser-saction li a {
  border: 1px solid #d5a67b;
}

.prvacy-box input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.prvacy-box label {
  position: relative;
  cursor: pointer;
}
.prvacy-box label::before {
  content: "";
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #d5a67b;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  width: 21px;
  height: 21px;
  border-radius: 5px;
}
.prvacy-box input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 8px;
  width: 5px;
  height: 11px;
  border: solid #d5a67b;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.prvacy-box {
  text-align: left;
}
.prvacy-box a {
  color: #4e64dd;
  font-size: 14px;
  font-family: "Oakes-Grotesk-Regular";
  border-right: 1px solid #4e64dd;
  margin: 17.84px 10px 0 0;
  padding: 0 10px 0 0;
}
.tr-pr {
  margin: 0 0 0 32px;
}
.tr-pr a:last-child {
  border-right: none;
}
.prvacy-box a:hover {
  color: #d5a67b;
}
.from-inner-box h4 {
  margin: 0 0 19.43px 0;
  text-align: left;
  width: 100%;
  color: #2f2f2f;
  font-size: 14px;
  font-weight: 500;
  font-family: "Oakes-Grotesk-Medium";
  display: inline-block;
}
.eth-pri a {
  margin: 0 0 0 10px;
  border: none;
  padding: 0;
}
.cuntrey-website input {
  margin-bottom: 0;
}
.in-order .check-field {
  margin-bottom: 0;
}
.thanku-popup button {
  display: inline-block;
  width: 184px;
  background: #d5a67b;
  color: white;
  border: 0 none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 5px 10px #e0e4ee;
  height: 61px;
  font-family: "Oakes-Grotesk-Medium";
  font-weight: 500;
  font-size: 16px;
  margin: 58.9px 50.5px 58.9px !important;
}
.thanku-popup button:hover {
  background-color: #fff;
  color: #d5a67b;
}
.thanku-popup button:focus {
  background-color: #d5a67b !important;
}
.thanku-popup .modal {
  text-align: center;
  padding: 0 !important;
}
.thanku-popup .modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.thanku-popup .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  width: 100%;
}
.thanku-popup .modal-dialog {
  max-width: inherit;
  width: 605.5px;
  border: 2px solid #d5a67b;
  box-shadow: 0px 48px 90px rgba(0, 0, 0, 0.4);
  border-radius: 21px;
  overflow: hidden;
  background-color: #fff;
  height: 494.6px;
  text-align: center;
  padding: 56.86px 0;
}
.thanku-popup .modal-content {
  border: none;
  border-radius: 0;
  background-color: transparent;
  margin: 0;
}
.thanku-popup .modal-content {
  border: none;
  border-radius: 0;
  background-color: transparent;
  margin: 0;
}
.thanku-popup p {
  color: #000;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 1px;
  font-family: "Oakes-Grotesk-Light";
  margin: 23.3px 0 0 0;
}
.closebt {
  text-align: center;
  width: 100%;
  display: inline-block;
}
.closebt .close {
  float: inherit !important;
  background-color: #d5a67b !important;
  opacity: inherit !important;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 5px 10px #e0e4ee;
  width: 184px !important;
  height: 61px !important;
  margin: 37px 0 0 0 !important;
  font-size: 16px;
  font-weight: 500;
  font-family: "Oakes-Grotesk-Medium";
}
.close:hover {
  color: #fff !important;
}
.thanku-popup .modal-dialog::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 90%;
  top: 7%;
  bottom: 0;
  height: 90%;
  background: url("/images/Union.png");
  background-size: cover;
  background-position: 100%;
  margin: 0 auto;
}

.register-clint .logo {
  margin-top: 50px;
}
.register-clint {
  padding-bottom: 100px;
}

.check-box a {
  margin: 4px 0 0 32px;
  display: inline-block;
}
.check-box.opt-in {
  margin-top: 15px;
}
.register-clint .dont-have-acc {
  margin: 36.1px 0 0 0;
}

.check-field input[type="checkbox"] {
  width: 100%;
  margin: 0;
  padding: 0;
  opacity: 1;
  appearance: none;
  height: 100%;
  position: absolute;
  background-color: transparent;
  cursor: pointer;
}
.check-field input[type="checkbox"]::before {
  content: "";
  width: 21px;
  height: 21px;
  position: absolute;
  border: 1px solid #cacaca;
  border-radius: 5px;
  left: 15px;
  top: 13px;
}
.check-field input[type="checkbox"]:checked::before {
  border-color: #d5a67b;
  background-color: #d5a67b;
}
.check-field input[type="checkbox"]:checked::after {
  content: "";
  background: url(/images/check.png);
  width: 9.93px;
  height: 8.83px;
  position: absolute;
  left: 20px;
  background-size: cover;
  top: 19px;
}
.check-field input[type="checkbox"]:checked {
  border: 1px solid #d5a67b;
}

.ul-li-list .check-field label .brown-imag {
  display: none;
}
.ul-li-list .check-field {
  padding: 0;
  width: 100%;
  margin: 0;
  height: 100%;
  float: inherit;
  text-align: center;
}
.ul-li-list .check-field label {
  width: 100%;
  height: 100%;
  font-size: 13px;
  line-height: 17px;
  padding: 0;
  display: inline-block;
  border: none;
  border-radius: 0;
  text-align: center;
}
.ul-li-list .check-field img {
  display: inline;
}
.ul-li-list .check-field label {
  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 17px;
  padding: 98px 0 12px 0;
  display: inline-block;
  border: none;
  border-radius: 4px;
  text-align: center;
  font-weight: 300;
  font-family: "Oakes-Grotesk-Regular";
  color: #2f2f2f;
}
.ul-li-list li input {
  border: 1px solid #efefef;
  overflow: hidden;
}
.ul-li-list li:nth-child(1) .check-field input::before {
  content: "";
  background: url(/images/windo-gray.png);
  border: none;
  width: 43px;
  height: 48px;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 22.65px;
  margin: 0 auto;
  border-radius: 0;
}
.ul-li-list li:nth-child(1) input[type="checkbox"]:checked::before {
  content: "";
  background: url(/images/windo-brown.png);
  border: none;
  width: 43px;
  height: 48px;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  border-radius: 0;
  top: 22.65px;
}
.ul-li-list li:nth-child(2) .check-field input::before {
  content: "";
  background: url(/images/desk-gray.png);
  border: none;
  width: 55px;
  height: 45px;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 22.65px;
  margin: 0 auto;
  border-radius: 0;
}
.ul-li-list li:nth-child(2) input[type="checkbox"]:checked::before {
  content: "";
  background: url(/images/desk-brown.png);
  border: none;
  width: 55px;
  height: 52px;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  /* top: 0; */
  margin: 0 auto;
  border-radius: 0;
  top: 17.65px;
}
.ul-li-list li:nth-child(3) .check-field input::before {
  content: "";
  background: url(/images/tablet-gray.png);
  border: none;
  width: 43px;
  height: 46px;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 22.65px;
  margin: 0 auto;
  border-radius: 0;
}
.ul-li-list li:nth-child(3) input[type="checkbox"]:checked::before {
  content: "";
  background: url(/images/tablet-brown.png);
  border: none;
  width: 43px;
  height: 46px;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 0;
  top: 22.65px;
}
.ul-li-list li:nth-child(4) .check-field input::before {
  content: "";
  background: url(/images/tablet.png);
  border: none;
  width: 43px;
  height: 46px;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 22.65px;
  margin: 0 auto;
  border-radius: 0;
}
.ul-li-list li:nth-child(4) input[type="checkbox"]:checked::before {
  content: "";
  background: url(/images/tablet-two-brown.png);
  border: none;
  width: 43px;
  height: 46px;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 0;
  top: 22.65px;
}
.ul-li-list li:nth-child(5) .check-field input::before {
  content: "";
  background: url(/images/android-gray.png);
  border: none;
  width: 43px;
  height: 46px;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 22.65px;
  margin: 0 auto;
  border-radius: 0;
}
.ul-li-list li:nth-child(5) input[type="checkbox"]:checked::before {
  content: "";
  background: url(/images/android-brown.png);
  border: none;
  width: 43px;
  height: 46px;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 1px;
  margin: 0 auto;
  border-radius: 0;
  top: 22.65px;
}
.ul-li-list li:nth-child(6) .check-field input::before {
  content: "";
  background: url(/images/devices-gray.png);
  border: none;
  width: 43px;
  height: 46px;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 22.65px;
  margin: 0 auto;
  border-radius: 0;
}
.ul-li-list li:nth-child(6) input[type="checkbox"]:checked::before {
  content: "";
  background: url(/images/devices-brown.png);
  border: none;
  width: 43px;
  height: 46px;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0px;
  margin: 0 auto;
  border-radius: 0;
  top: 22.65px;
}
.browser-saction li input::before {
  display: none;
}
.browser-saction li input::after {
  display: none;
}
.browser-saction .icon-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 23px;
}

.ul-li-list .check-field input::after {
  display: none;
}
.brswe-file {
  margin: 0 0 12px 0;
}

.step-notifaction {
  background-color: rgba(255, 255, 255, 0.94);
  border-radius: 11px;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  margin-bottom: 36px;
}
.noti-icon {
  float: left;
  width: 52.54px;
  height: 92px;
  text-align: center;
  background-color: rgba(231, 215, 200, 0.31);
  line-height: 107px;
  margin: 0 0px 0 0;
}
.noti-cont {
  float: right;
  width: 93%;
  padding: 13px 20px 0 0px;
}
.noti-cont span {
  color: #2f2f2f;
  font-size: 14px;
  font-weight: 600;
}
.noti-cont p {
  margin: 4px 0 0 0;
  color: #2f2f2f;
  font-size: 14px;
  line-height: 22px;
}

.book-practice-guest-saction {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 8rem 0;
}
.practice-guest-box {
  display: inline-block;
  margin: 0 auto;
  width: 550px;
  background-color: #fff;
  border-top: 20px solid #ecd6c2;
  padding: 17px 26px;
  text-align: left;
}
.profile-boxx {
  display: inline-block;
  width: 100%;
  margin: 0;
}
.profile-boxx .profile-imgg {
  float: left;
  border: 2px solid #d5a67b;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  text-align: center;
  padding: 2px 2px;
}
.profile-boxx .profile-contt {
  float: right;
  width: 80%;
  text-align: left;
  margin: 10px 0 0 0;
}
.profile-contt span {
  font-size: 20px;
  color: #000000;
  font-weight: 600;
  font-family: "Oakes-Grotesk-Medium";
  width: 100%;
  display: inline-block;
}
.profile-contt .rating {
  display: inline-block;
  width: 100%;
  margin: 7px 0 0 0;
}
.profile-contt .rating em {
  font-style: normal;
  border-left: 1px solid #dcdcdc;
  margin: 0 12px;
  padding: 0 12px;
  color: #2f2f2f;
  font-size: 13px;
  font-weight: 500;
  font-family: "Oakes-Grotesk-Medium";
  position: relative;
  top: -4px;
}
.profile-boxx-lest {
  display: inline-block;
  width: 100%;
  margin: 29px 0 0 0;
}
.profile-boxx-lest ul {
  margin: 0;
  padding: 0;
  width: 100%;
}
.profile-boxx-lest ul li {
  display: inline-block;
  width: 100%;
  margin: 0 0 15px 0;
}
.profile-boxx-lest ul li .icon-gest {
  float: left;
  margin: 0 15px 0 0;
}
.profile-boxx-lest ul li {
  display: inline-block;
  width: 100%;
  margin: 0 0 15px 0;
  font-size: 14px;
  font-weight: 500;
  font-family: "Oakes-Grotesk-Medium";
  color: #000;
}
.profile-boxx-lest ul li span {
  margin: 0 0 0 15px;
  color: #d5a67b;
}
.profile-boxx-lest .dont-have-acc {
  margin: 0 0 0;
}
.profile-boxx-lest .btn.btn-info.btn-lg {
  width: 100%;
  padding: 0 !important;
  margin: 23px 0 19.57px 0;
  background-color: #d5a67b;
  height: 48px;
  color: #fff;
}
.gust-ppop .modal-dialog::before {
  display: none;
}
.gust-ppop .modal-body {
  background-color: transparent;
  padding: 0;
}
.gust-ppop .close {
  background-color: rgba(47, 47, 47, 0.06);
  width: 43px;
  height: 43px;
  border-radius: 50px;
  opacity: inherit !important;
  top: 20px;
  right: 16px;
  position: absolute;
  z-index: 99;
  cursor: pointer;
}
.gust-ppop .modal-dialog {
  height: 454px !important;
  border: none !important;
  padding-top: 70px;
}
.guest-popup h3 {
  margin: 30px 0;
  font-size: 24px;
  font-family: "Oakes-Grotesk-Medium";
  font-weight: 600;
  line-height: 35px;
}
.guest-popup a {
  width: 300px;
  height: 61px;
  background-color: #d5a67b;
  display: inline-block;
  line-height: 61px;
  color: #fff !important;
  border-radius: 5px;
  box-shadow: 0px 5px 10px #e0e4ee;
  font-size: 16px;
  font-family: "Oakes-Grotesk-Medium";
  font-weight: 500;
  margin: 47px 0 0 0;
  text-decoration: none !important;
}
.guest-page .prgress-baar-new {
  width: 100%;
}
.guest-page .prgress-baar-new::after {
  /* content: '100%'; */
}
