.embeded-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.embeded-container {
  background-color: white;
  width: 381px;
  /* height: 240px; */
  padding: 65px 50px;
  /* border: 1px solid black; */
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.embeded-btn-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.embeded-container .embeded-btn-container a {
  width: 251px;
  height: 48px;
  border-radius: 7px;
  border: 1px solid #d5a67b;
  display: flex;
  align-items: center;
  justify-content: center;
}
.embeded-container .btn1 {
  background: #ffffff;
  color: #d5a67b;
}

.embeded-container .btn2 {
  background: #d5a67b;
  color: #ffffff;
  border-radius: 7px;
  margin-top: 15px;
}
.embeded-powered-container {
  margin-top: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.F8F9FA{
  background-color:  #F8F9FA !important;
}