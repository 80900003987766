.search-booking-page {
  width: 530px !important;
}

.item-list-search {
  border-bottom: 1px solid #cacaca;
  margin: 15px;
  text-align: left;
}
.item-list-search:hover {
  /* background-color: white;
    color:#d5a67b ; */
}
.select-box-booking select {
  padding: 10px;
  margin: 20px;
  width: 100%;
  border-radius: 10px;
}
.select-box-booking {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.medium-booking-container {
  max-width: 1200px;
  margin: 0 auto;
}
.medium-booking-container-back-btn {
  background: #00000014;
  height: 31px;
  display: inline-flex;
  align-items: center;
  padding: 10px;
  border-radius: 7px;
}
.selection-box-medium-booking .css-14el2xx-placeholder {
  color: hsl(0deg 0% 50% / 50%);
  font-size: 13.1px;
  font-weight: 100;
  font-style: normal;
  font-family: "Oakes-Grotesk-Regular";
}

.selection-box-medium-booking .css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}
.guest-booking-country-language .css-14el2xx-placeholder {
  font-size: 14px !important;
}
/* .input-booking-page input[type="datetime-local"]:before {
  content: attr(placeholder) !important;
  color: #aaa;
  margin-right: 0.5em;
}
.input-booking-page input[type="datetime-local"]:focus:before,
.input-booking-page input[type="datetime-local"]:valid:before {
  content: "";
} */

.text-section-medium-booking p {
  font-size: 16px;
  line-height: 23px;
}
.bacground-unset {
  background-color: unset !important;
}
.Greay-bg {
  background-color: #f4f6f6;
}
.Green-bg {
  background-color: #d4efdf;
}
.Green-bg-all{
  background-color: green !important;
}
.select-box-1-practive-book::before {
  content: "1";
  height: 20px;
  width: 20px;
  display: block;
}
.step-no-booking {
  font-size: 30px;
  color: #d5a67b;
}
.practice-booking-step-container #progressbar {
  width: 100% !important;
}

.practice-booking-step-container #progressbar li {
  width: 15% !important;
}
.btn-disabled-practice {
  cursor: not-allowed !important;

  background: #8080804a !important;
}

.practice-my-app {
  font-size: unset !important;
  text-decoration: underline !important;
  color: #555;
}
.skip-pbtn {
  background: #80808038 !important;
  color: #00000078 !important;
  border: 1px solid !important;
}
.select_partner_level input[type="checkbox"]:checked::before {
  border-color: #8080804a;
  background-color: #8080804a;
}
.select_partner_level input[type="checkbox"]:checked {
  border: 1px solid #8080804a !important;
}
.current-active > span {
  border: 2px solid #00000040 !important;
  box-shadow: 0px 8px 22px #0000009c !important;
  transition: 0.5s;
}

.practice-booking-step-container #progressbar li span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
}
.practice-booking-step-container .current-active strong,
.practice-booking-step-container .active strong {
  color: #555 !important;
}
.practice-booking-step-container #progressbar li strong {
  color: #8080804f;
  font-size: 24px;
  width: unset;
}
.practice-booking-step-container .step-image-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.practice-booking-step-container .step-image-box img {
  margin: 18px 0 0 0;
}
@media (max-width: 575.98px) and (orientation: portrait) {
  .search-booking-page,
  .select_partner_level {
    width: unset !important;
  }
  .Please-check-your-personal-calendar {
    margin: unset !important;
  }
  .Please-check-your-personal-calendar .resend-btn {
    width: 100px !important;
  }
  .medium-coaching-btns {
    gap: 3px !important;
  }
  .medium-coaching-btns-new {
    gap: 3px !important;
  }
}
@media (max-width: 800px) and (orientation: landscape) {
  .search-booking-page,
  .select_partner_level {
    width: unset !important;
  }
  .booking {
    display: none;
  }
  .booking-inner {
    display: none;
  }
  .logout-box {
    display: none !important;
  }
  .navbar-nav {
    overflow-x: auto;
  }
}

@media (max-width: 500px) {
  .practice-booking-step-container #progressbar li span {
    width: 45px !important;
    height: 45px !important;
  }
  .practice-booking-step-container #progressbar li strong {
    font-size: 14px;
  }
  .practice-booking-step-container .step-image-box img {
    margin: 10px 0 0 0;
    width: 10px !important;
  }
}
.booking-modal-confirm .modal-dialog {
  height: 340.74px !important;
}
.medium-coaching-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.medium-coaching-btns button {
  width: 180px !important;
  height: 100px !important;
  line-height: 20px;
  padding: 14px;
}
.medium-coaching-btns-new {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.medium-coaching-btns-new button {
  width: 180px !important;
  height: 71px !important;
  line-height: 20px;
  padding: 14px;
}

.limit-reached-popup-buttons {
  width: 300px !important;
  line-height: 20px;
  height: 90px !important;
  padding: 10px;
}
.limit-reached-popup-buttons div {
  display: flex;
  align-items: center;
}
.limit-reached-img-btn {
  width: 80px;
  display: inline-block;
  height: 70px;
  background: white;
  border-radius: 50%;
  padding: 10px;
}
.limit-reached-img-btn img {
  width: 100%;
  height: 100%;
}
.limit-reached-img-btn-2 {
  width: 109px !important;
}

.calendar-overlay-booking-medium-calendar {
  background: #d5a67ba1;
  position: absolute;
  height: 89%;
  width: 92%;
  z-index: 8;
  border-radius: 5px;
}

.calendar-overlay-booking-medium-calendar-internal {
  justify-content: space-around;
  font-size: 24px;
  color: black;
  display: flex;
  align-items: center;
  height: 100%;
}
.calendar-overlay-booking-medium-calendar-internal img {
  height: 90px;
}

.calendar-overlay-booking-medium-calendar-internal div {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  background: darkgoldenrod;
}
.alert-before-coaching-box::before {
  content: "*Prices are set by the coaches";
  display: block;
  height: 20px;
  color: red;
  top: -19px;
  position: absolute;
  left: 100px;
}

.profile-card-parent{
  max-height: 740px;
  overflow: scroll;
}
.min-width-360-px{
  min-width: 360px;
}